import './OtpConfirmForm.scss'
import React, {createRef,RefObject, useEffect, useRef, useState} from 'react';
import {Form, Formik, useFormikContext} from "formik";
import {Button, Card, Col, Image, Row, Spinner} from "react-bootstrap";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import {authService} from "../../../../services/auth.service";
import {PhoneNumber, OtpResponse, otpInitialValues} from "../../core/_models";
import clsx from "clsx";
import {CustomerServiceButton} from "../../../../components/CustomerServiceBtn/CustomerServiceButton";
type Otp = Array<number | string>;
const INPUTS_LENGTH = 6;

interface Props {
    phoneNumber: PhoneNumber;
    handleOtp?: (authSuccess: OtpResponse) => void;
    handleApiError: (value:boolean) => void;
}

interface AutoSubmitProps {
    phoneNumber:PhoneNumber;
    otpString: string;
    verifyOtpSuccess?: OtpResponse;
    setVerifyOtpSuccess?:(value: OtpResponse) => void;
    setHasError?: (value: boolean | undefined) => void;
    setStatusError?: (value: string| undefined) => void;
    recaptcha_token?: string;
}

const verifyOtp = async (phoneNumber,otpString,recaptcha_token) => {
    const otp_code = Number(otpString);
    const otpConfirm = await authService.confirmOtp(phoneNumber, recaptcha_token,otp_code);
   return otpConfirm;
}

const AutoSubmitToken = ({otpString}: AutoSubmitProps) => {
    const [lastOptChar, setLastOptChar] = useState<string>('');
    const {submitForm} = useFormikContext();
    
    useEffect(() => {
        if (otpString.length === INPUTS_LENGTH) {
            setLastOptChar(otpString.slice(-1));
        }
    }, [otpString])

    useEffect(() => {
        if (otpString.length === INPUTS_LENGTH) {
            submitForm();
        }
    }, [lastOptChar, otpString.length, submitForm]);
    // Grab values and submitForm from context
    return null;
};

export const OtpConfirmForm = ({phoneNumber,handleOtp,handleApiError}: Props) => {
    const [otp, setOtp] = useState<Otp>(Array.from({length: INPUTS_LENGTH}, () => ''));
    const [inputsRef] = useState<Array<RefObject<HTMLInputElement>>>(Array.from({length: INPUTS_LENGTH}, () => createRef<HTMLInputElement>()));
    const [hasError, setHasError] = useState<boolean | undefined>(undefined);
    const [statusError, setStatusError] = useState<string>('');
    const [currentInputIdx, setCurrentInputIdx] = useState(0); //Index of first input to focus;
    const reCaptchaRef = useRef<ReCAPTCHA>(null);
    const [verifyOptSuccess, setVerifyOtpSuccess] = useState<OtpResponse>(otpInitialValues);
    const [isResending, setIsResending] = useState<boolean>(false);
    const navigate = useNavigate();
    const handleKeys = (ev: React.KeyboardEvent<HTMLInputElement>, idx: number) => {

        //Resetting error when user retype.
        if (hasError) setHasError(undefined);

        const {key} = ev;
        const currInput = inputsRef?.[idx]?.current;
        const nextInput = inputsRef?.[idx + 1]?.current;
        const prevInput = inputsRef?.[idx - 1]?.current;

        const newOtp = [...otp];

        setTimeout(() => {
            if (key === 'ArrowLeft') {
                if (!prevInput) return;
                prevInput?.focus();
                setCurrentInputIdx(idx - 1);
            } else if (key === 'ArrowRight' && nextInput) {
                nextInput?.focus();
                setCurrentInputIdx(idx + 1);
                if (nextInput?.selectionStart === 0) {
                    nextInput.setSelectionRange(1, 1);
                    return;
                }
            } else if (key === 'Backspace') {
                if (!currInput?.value) {
                    prevInput?.focus();
                    if (currentInputIdx <= 0) return;
                    setCurrentInputIdx(idx - 1);
                }
                newOtp[idx] = '';
                setOtp(newOtp);
                return;
            } else if (/^\d+$/.test(key)) {
                newOtp[idx] = key;
                setOtp(newOtp);
                if (!nextInput) return;
                nextInput?.focus();
                setCurrentInputIdx(idx + 1);
            }

        })
    }

    useEffect(() => {
        if(verifyOptSuccess.success){
            navigate(`/tickets_list`, { state: { phone_number: phoneNumber }});
        }

    },[verifyOptSuccess, navigate, phoneNumber]);

    const handleResendOtp = async () => {
        try {
            setIsResending(true);
            const recaptcha_token = await reCaptchaRef?.current?.executeAsync();
            if (!recaptcha_token) {
                setStatusError(t("t:LOCATE_TICKETS.VERIFY_OTP_FAIL"));
                setHasError(true)
                return;
            }
            const otpResult = await authService.getOtp(phoneNumber, recaptcha_token);
            handleOtp({
                success: otpResult.status_code === 0,
                showResult: true,
                status_code: otpResult.status_code,
                block_user: otpResult.block_user,
                status_msg: otpResult.status_msg
            });
            //reset recaptcha
            reCaptchaRef?.current?.reset();
        } catch (error){
            reCaptchaRef?.current?.reset();
            handleApiError(true);
        } finally {
            setIsResending(false);
        }
    }

    return (
      <div>
        <Formik
          initialValues={{ otp_code: otp.join("") }}
          // validationSchema={LoginSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(async () => {
              try {
                setSubmitting(true);
                const recaptcha_token =
                  await reCaptchaRef?.current?.executeAsync();

                if (!recaptcha_token) {
                  setSubmitting(false);
                  setStatusError(t("t:LOCATE_TICKETS.VERIFY_OTP_FAIL"));
                  setHasError(true);
                  return;
                }

                const otpString = otp?.join("");
                const otpVerifyResult = await verifyOtp(
                  phoneNumber,
                  otpString,
                  recaptcha_token
                );
                const confirmSuccess = otpVerifyResult.status_code === 0;
                setVerifyOtpSuccess({
                  success: otpVerifyResult.status_code === 0,
                  showResult: true,
                  status_code: otpVerifyResult.status_code,
                  block_user: otpVerifyResult.block_user,
                  status_msg: otpVerifyResult.status_msg,
                });
                setHasError(!confirmSuccess);
                setStatusError(
                  t(
                    `t:LOCATE_TICKETS.VERIFY_OTP_ERROR_${otpVerifyResult.status_code}`
                  )
                );
                //reset recaptcha
                reCaptchaRef?.current?.reset();
                setSubmitting(false);
              } catch (error: any) {
                reCaptchaRef?.current?.reset();
                setSubmitting(false);
                handleApiError(true);
              }
            }, 100);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <ReCAPTCHA
                style={{ opacity: "0.6" }}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ""}
                size="invisible"
                // hl={selectedLang}
                ref={reCaptchaRef}
              />
              <Row className="justify-content-center p-0 mx-0">
                <Card.Title>
                  {verifyOptSuccess.success
                    ? t("t:LOCATE_TICKETS.SEND_OTP_VERIFY_SUCCESS")
                    : t("t:LOCATE_TICKETS.OTP_CONFIRM_TITLE")}
                </Card.Title>
                {hasError ? (
                  <Image
                    src="media/images/errorIcon.svg"
                    className="error-icon"
                  />
                ) : verifyOptSuccess.success ? (
                  <Image
                    src="media/images/successIcon.svg"
                    className="error-icon"
                  />
                ) : (
                  <Card.Text>
                    {t("t:LOCATE_TICKETS.OTP_CONFIRM_SUBTITLE", {
                      phone_number:
                        "{" +
                        phoneNumber.area_code +
                        "-" +
                        phoneNumber.phone_number +
                        "}",
                    })}
                  </Card.Text>
                )}
                <Row className={`otp-container`} style={{ direction: "ltr" }}>
                  {inputsRef.map((ref, idx) => {
                    const classesNotLastEl =
                      idx === otp.length - 1 ? "" : `me-2`;
                    const showResult = otp.join("").length === INPUTS_LENGTH;
                    return (
                      <Col className="mx-2 p-0 " key={idx}>
                        <input
                          aria-label={t("t:OTP_CONFIRM_FORM.INPUT_ARIA_LABEL")}
                          aria-describedby={t("t:OTP_CONFIRM_FORM.INPUT_ARIA_DESCRIPTION", { currentCount: idx + 1, totalCount: INPUTS_LENGTH })}
                          autoFocus={idx === 0}
                          ref={ref}
                          className={clsx(
                            `otp-input w-100 text-center rounded `,
                            { "border-0": !showResult },
                            { "otp-valid": verifyOptSuccess.success },
                            { "otp-invalid": hasError }
                          )}
                          type="text"
                          inputMode="numeric"
                          maxLength={1}
                          name="otp"
                          value={otp[idx]}
                          // onChange={(ev) => handleChange(ev, idx)}
                          onKeyDown={(ev) => handleKeys(ev, idx)}
                        />
                      </Col>
                    );
                  })}
                </Row>
                <span className="error-msg text-danger my-2">
                  {hasError && statusError}
                </span>
                {verifyOptSuccess.block_user === "Y" && (
                  <CustomerServiceButton />
                )}
                {verifyOptSuccess.block_user === "N" && (
                  <>
                    {verifyOptSuccess.status_code !== 20010 && (
                      <Row className="my-3">
                        <Button type="submit" aria-label={t("t:OTP_CONFIRM_FORM.SUBMIT_BTN_ARIA_LABEL")} disabled={isSubmitting}>
                          {isSubmitting ? (
                            <Spinner
                              animation="border"
                              variant="light"
                              role="status"
                            />
                          ) : (
                            t("t:LOCATE_TICKETS.SEND_OTP_VERIFY")
                          )}
                        </Button>
                      </Row>
                    )}
                    <Row>
                      <span className="resend-otp">
                        {t("t:LOCATE_TICKETS.RESEND_OTP_TEXT")}
                        <Button
                          variant="link"
                          aria-label={t("t:OTP_CONFIRM_FORM.RESEND_BTN_ARIA_LABEL")}
                          className={clsx("bg-transparent", {"resend-otp-disabled": isResending})}
                          onClick={() => handleResendOtp()}
                        >
                          {isResending
                            ? t("t:LOCATE_TICKETS.RESEND_OTP_LINK_SENDING")
                            : t("t:LOCATE_TICKETS.RESEND_OTP_LINK")}
                        </Button>
                      </span>
                    </Row>
                  </>
                )}
              </Row>
              <AutoSubmitToken
                otpString={otp.join("")}
                phoneNumber={phoneNumber}
              />
            </Form>
          )}
        </Formik>
      </div>
    );
}