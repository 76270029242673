import { httpService } from './https.service';
import { storageService } from './storage.service';
import {PhoneNumber} from "../pages/LocateTickets/core/_models";

export const authService = {
    getOtp,
    confirmOtp,
}

async function getOtp(phone_number:PhoneNumber, recaptcha_token:string ) {
    try {
        const response = await httpService.post('login/otp', { ...phone_number,recaptcha_token });
        if(response.block_user === "Y"){
            storageService.setToLocalStorage('loggedinUser', {...response});
        }
        return response;
    } catch (error) {
        throw error;
    }
}

async function confirmOtp(phone_number:PhoneNumber,  recaptcha_token: string,  otp_code: number) {
    try {
        const response = await httpService.post('login/otp/verify', { ...phone_number,recaptcha_token,otp_code });
        const { refresh_token } = response;

        storageService.setRefreshToken(refresh_token);
        delete response.refresh_token;
        storageService.setToLocalStorage('loggedinUser', {phone_number:phone_number,...response});
        return response;
    } catch (error) {
        throw error;
    }
}