import "./DownloadTicket.scss";
import React from "react";
import { useTranslation } from 'react-i18next';
import { Item, Order } from "../../core/_models";
import { isOrderTixstock } from "../../../../services/utils.service";

type Props = {
  item: Item;
  order: Order | null;
  ticketNumber: number;
};

const DownloadTicket: React.FC<Props> = ({ item, order, ticketNumber }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="download-item">
      <div className="title-container">
        {order !== null && (
          <span className="title">
            {
              isOrderTixstock(order.itinerary_type_id)
                ? t("t:TICKET_LIST.DOWNLOAD_ITEM.TITLE", { number: ticketNumber })
                : item.age_group_description
            }
          </span>
        )}
      </div>
      <a
        className="download-btn"
        href={item.voucher_url}
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          className="btn-icon"
          src="media/images/download-icon.svg"
          alt="download icon"
        />
      </a>
    </div>
  );
};

export { DownloadTicket };
