import { httpService } from "./https.service";
import { 
  FAQResponse, 
  LegalResponse, 
  TermsResponse,
  AccessibilityResponse 
} from "../common/types";

export const clientService = {
  getFAQs,
  getLegalData,
  getTermsData,
  getAccessibility,
};

async function getFAQs() {
  try {
    const response: FAQResponse = await httpService.post("reseller/faqs/get");
    if (response.status_code !== 0) {
      throw response;
    }

    return response.faqs;
  } catch (error) {
    throw error;
  }
}

async function getLegalData() {
  try {
    const response: LegalResponse = await httpService.post(
      "reseller/legal/get"
    );
    if (response.status_code !== 0) {
      throw response;
    }

    return response.legal_page;
  } catch (error) {
    throw error;
  }
}

async function getTermsData() {
  try {
    const response: TermsResponse = await httpService.post(
      "reseller/terms/get"
    );
    if (response.status_code !== 0) {
      throw response;
    }

    return response.terms_page;
  } catch (error) {
    throw error;
  }
}

async function getAccessibility() {
  try {
    const response: AccessibilityResponse = await httpService.post(
      "reseller/accessibility/get"
    );

    if (response.status_code !== 0) {
      throw response;
    }

    return {accessibility: response.accessibility_page};
  } catch (error: unknown) {
    throw error;
  }
}