import './TicketItemDetails.scss';
import moment from 'moment';
import React from 'react';
import { Order } from '../../core/_models';

type Props = {
    order: Order;
};

const TicketItemDetails: React.FC<Props> = ({ order }: Props) => {
    return (
        <div className="order-item-details">
            {order.venue_name && <span>{order.venue_name}</span>}
            <div className="execution-date">
                {order.itinerary_type_id !== "MONETARY_CODE" && order.execution_start_date && (
                    <span>
                        {moment(order.execution_start_date).format(
                            "DD.MM.YYYY"
                        )}
                    </span>
                )}
                {order.itinerary_type_id !== "MONETARY_CODE" && order.execution_start_time && (
                    <span>{order.execution_start_time}</span>
                )}
            </div>
        </div>
    );
};

export { TicketItemDetails };