import Axios, { AxiosRequestConfig, Method } from 'axios';
import { storageService } from './storage.service';
import { createTokens, getCSRF } from './token.service';
// import {useNavigate} from "react-router-dom";

type AxiosOptions = {} | AxiosRequestConfig;

interface HttpsModel {
    get: (endpoint: string, data?: any) => Promise<any>
    post: (endpoint: string, data?: any, options?: AxiosOptions) => Promise<any>
    put: (endpoint: string, data?: any) => Promise<any>
    delete: (endpoint: string, data?: any) => Promise<any>
}

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export var axios = Axios.create({
    withCredentials: true,
    timeout: 30000,
})

async function handleCSRF(endpoint: string, data: any = {}) {
    try {
        const notCSRFPaths = [ // Do not need csrf_token
            'login',
            'login/otp',
            'login/otp/verify',
            'logout',
            'csrf_token',
            'create_tokens',
            'lists/languages',
            'createToken',
            'reseller/faqs/get',
            'reseller/legal/get',
            'reseller/terms/get',
            'reseller/accessibility/get',
        ];

        let isNotCSRFPath = notCSRFPaths.some((notCSRFPath) => {
            return endpoint.includes(notCSRFPath);
        });

        if (isNotCSRFPath) {
            return data;
        }

        const csrf_token = await getCSRF();
        data = { ...data, csrf_token };
        return data;
    } catch (error: any) {
        throw error;
    }
}

function handleResponseLanguage(endpoint: string, data: any = {}) {


    const notResLangPaths = [ // Do not need response_language
        'user/update',
    ];

    const isNotResLangPath = notResLangPaths.some(notResLangPath => {
        return endpoint.includes(notResLangPath);
    });

    if (isNotResLangPath) {
        return data;
    }

    // const response_language = storageService.getCurrentLanguage();
    // return { ...data, response_language };
    // return { ...data, 'language_id': 'he'};
    return { ...data, 'response_language': 'he'};
}

function isSessionExpired(status_code) {
    const expirationsStatusCodes = [20001, 20003, 20004, 10000];
    return expirationsStatusCodes.includes(status_code);
}

export const httpService: HttpsModel = {
    get(endpoint, data) {
        return ajax(endpoint, 'GET' as Method, data)
    },
    post(endpoint, data, options) {
        return ajax(endpoint, 'POST' as Method, data, options)
    },
    put(endpoint, data) {
        return ajax(endpoint, 'PUT' as Method, data)
    },
    delete(endpoint, data) {
        return ajax(endpoint, 'DELETE' as Method, data)
    }
}


const ajax = async (endpoint: string, method = 'GET' as Method, data = null, options = {}): Promise<any> => {
    data = handleResponseLanguage(endpoint, data);
    //if current ajax is csrf_token or create_tokens,
    const endpointsToSkip = ['csrf_token', 'create_tokens', 'createToken', 'login'];
    const isEndpointToSkip = endpointsToSkip.some(endpointToSkip => endpoint?.includes(endpointToSkip));
    try {
        if (isEndpointToSkip) {
            const resp = await _ajax(endpoint, method, data, options);
            return resp;
        }
    } catch (error) {
        throw error;
    }

    return new Promise((res, rej) => {
        addToStack({
            endpoint,
            method,
            data,
            options
        }, res, rej);
    })
}

let isOpen = true;
const stack: any[] = [];

const addToStack = (data: any, res: any, rej: any) => {
    stack.push([data, res, rej]);
    nextAction();
}

const nextAction = () => {
    if (!isOpen) { return; }

    const next = stack.shift();
    if (!next) { return }
    dispatchAction(next);
}

const dispatchAction = async (action: any) => {
    isOpen = false;
    const [d, res, rej] = action;

    const {
        endpoint,
        method,
        data,
        options
    } = d;

    try {
        const newData = await handleCSRF(endpoint, data);
        const resp = await _ajax(endpoint, method, newData, options);
        res(resp);
    } catch (error: any) {
        if (isSessionExpired(error?.status_code)) {
            await createTokens();
            const newData = await handleCSRF(endpoint, data);
            const resp = await _ajax(endpoint, method, newData, options);
            res(resp);
        } else {
            rej(error);
        };
    }
    isOpen = true;
    nextAction();
}

async function _ajax(endpoint: string, method = 'GET' as Method, data = null, options = {}): Promise<any> {
    try {
        const res = await axios({
            url: `${BASE_URL}${endpoint}`,
            method,
            data,
            params: (method === 'GET') ? data : null,
            ...options
        })
        return res.data;
    } catch (error: any) {
        throw error
    }
}
