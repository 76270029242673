import './CustomDrawer.scss';
import * as React from 'react';
import {styled} from '@mui/material/styles';
import {grey} from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Drawer} from "@mui/material";
import {Image, Row} from "react-bootstrap";

import {t} from "i18next";
import {CustomerServiceButton} from "../CustomerServiceBtn/CustomerServiceButton";

const drawerBleeding = 56;

const Root = styled('div')(({theme}) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({theme}) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

interface props {
    actionResponse: {success: boolean, title:string, msg: string , drawer_title:string}
    show: boolean,
    handleDrawerToggle: () => void
}

export const CustomDrawer = ({actionResponse,show, handleDrawerToggle}: props) => {
    const drawerContainer = (
        <div>
            <StyledBox
                sx={{
                    backgroundColor: '#F9FAFE'
                }}
            >
                <Puller/>
                <Typography sx={{p: 2, color: '#001E7F',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    lineHeight: '24px',
                    marginTop: '10px',
                }}>
                    {actionResponse.drawer_title}
                    <button type="button" className="btn-close m-auto" onClick={handleDrawerToggle} aria-label="Close"></button>
                </Typography>

            </StyledBox>
            <StyledBox
                sx={{
                    px: 2,
                    pb: 2,
                    height: '100%',
                    overflow: 'auto',
                    backgroundColor: '#F9FAFE'
                }}
            >

                <Row className="custom-modal-body">
                    <Row className="text-center">
                        <Image className="error-icon text-center"
                               src={actionResponse.success ? 'media/images/successIcon.svg' : 'media/images/errorIcon.svg'}/>
                        <span className="title">{actionResponse.title}</span>
                        <span className="confirm-text">
                    {actionResponse.msg}
                        </span>
                    </Row>
                    <Row className="px-5">
                        {actionResponse.success ?
                            <Button variant="contained"  className="close-btn" onClick={handleDrawerToggle}>{t("t:TICKETS_LIST.CLOSE_MODAL")}</Button> :
                            <CustomerServiceButton/>}
                    </Row>
                </Row>


            </StyledBox>
        </div>
    );

    return (

        <Drawer
            variant="temporary"
            anchor="bottom"
            open={show}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: {xs: 'block', sm: 'none'},
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '100%',
                    marginTop: '28px',
                    borderRadius: '28px 28px 0 0',
                    backgroundColor: '#F9FAFE'
                },
            }}
        >

            {drawerContainer}
        </Drawer>

    );
}
