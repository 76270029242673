import './CustomerServiceBtn.scss'
import React from "react";
import {Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


export const CustomerServiceButton = () => {
    const { t } = useTranslation();
    return (
        <Link className="customer-service-link text-decoration-none  py-2"
              target="blank" aria-label={t("t:CUSTOMER_SERVICE_ARIA_LABEL")}
              aria-describedby={t("t:CUSTOMER_SERVICE_ARIA_DESCRIPTION")}
              to={`${process.env.REACT_APP_CUSTOMER_SERVICE_URL}${t("t:CUSTOMER_SERVICE.SUPPORT_TEXT")}`}>
            <span className="ms-1">{t("t:FOOTER.CUSTOMER_SERVICE_BTN")}</span>
            <Image fluid={true} width="24px" height="24px" className="" src="media/images/whatsapp-logo.svg"/>
        </Link>
    );
}

