import "./Autocomplete.scss";
import clsx from "clsx";
import React, { useState, useRef, useEffect } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useDebounce } from "../../hooks/useDebounce";

interface Option {
  label: string;
  value: string;
}

type Props = {
  type?: string;
  name: string;
  options: Array<Option>;
  value: string;
  ariaLabel: string;
  inputClass: string;
  listClass?: string;
  showToggle?: boolean;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (ev: React.FocusEvent) => void;
  onSelect: (selectedItem: string) => void;
};

const Autocomplete: React.FC<Props> = ({
  type = "text",
  name,
  options,
  value,
  ariaLabel,
  inputClass = "",
  listClass = "",
  showToggle = true,
  onChange,
  onBlur,
  onSelect,
}: Props) => {
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [suggestions, setSuggestions] = useState<Array<Option>>([]);
  const debouncedValue = useDebounce(value, 100);
  const autocompleteRef = useRef<HTMLDivElement>(null);
  const suggestionsRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (options.length > 0) {
      const filteredSuggestions = options.filter((option: Option) => {
        return (
          option.label.toLowerCase().indexOf(debouncedValue.toLowerCase()) > -1
        );
      });
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions(options);
    }
  }, [debouncedValue, options]);

  useEffect(() => {
    if (suggestionsRef.current && selectedIndex !== null) {
      const selectedIndexElement =
        suggestionsRef.current.children[selectedIndex];
      if (selectedIndexElement) {
        selectedIndexElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [selectedIndex]);

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "ArrowDown") {
      ev.preventDefault();
      const nextIndex =
        selectedIndex === null
          ? 0
          : Math.min(selectedIndex + 1, suggestions.length - 1);
      setSelectedIndex(nextIndex);
    } else if (ev.key === "ArrowUp") {
      ev.preventDefault();
      const prevIndex =
        selectedIndex === null
          ? suggestions.length - 1
          : Math.max(selectedIndex - 1, 0);
      setSelectedIndex(prevIndex);
    } else if (ev.key === "Enter") {
      ev.preventDefault();
      if (suggestions.length > 0 && selectedIndex !== null) {
        const selectedSuggestion = suggestions[selectedIndex].value;
        handleSelect(selectedSuggestion);
      }
    }
  };

  function handleSelect(suggestion: string) {
    onSelect(suggestion);
    setShowSuggestions(false);
    setSelectedIndex(null);
  }

  const handleToggle = (ev: React.MouseEvent) => {
    ev.preventDefault();
    setShowSuggestions(!showSuggestions);
  };

  useOnClickOutside(autocompleteRef, () => {
    setShowSuggestions(false);
  });

  return (
    <div className="autocomplete" ref={autocompleteRef}>
      <input
        type={type}
        autoComplete="off"
        name={name}
        className={inputClass}
        value={value}
        aria-label={ariaLabel}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setShowSuggestions(true)}
        onBlur={onBlur}
      />
      {showSuggestions && suggestions.length > 0 && (
        <ul className={`suggestions ${listClass}`} ref={suggestionsRef}>
          {suggestions.map((suggestion: Option, index: number) => (
            <li
              key={suggestion.label}
              className={`suggestion ${
                selectedIndex === index ? "selected" : ""
              }`}
              onClick={() => handleSelect(suggestion.value)}
            >
              {suggestion.label}
            </li>
          ))}
        </ul>
      )}
      {showToggle && (
        <button className="arrow-btn" onClick={handleToggle}>
          <img
            src="media/images/arrow-icon.svg"
            className={clsx({ "arrow-up": showSuggestions })}
            alt="autocomple arrow icon"
          />
        </button>
      )}
    </div>
  );
};

export { Autocomplete };