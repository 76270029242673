import React from 'react'
import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import CustomModal from "../CustomModal/CustomModal";
import {useTranslation} from "react-i18next";
import {Button, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const timeout = 900_000
const promptBeforeIdle = 30_000
// const timeout = 30_000
// const promptBeforeIdle = 25_000

export const IdleSession = () => {
    const { t } = useTranslation();
    const [state, setState] = useState<string>('Active')
    const [remaining, setRemaining] = useState<number>(timeout)
    const [open, setOpen] = useState<boolean>(false)

    const navigate = useNavigate();
    const onIdle = () => {
        setState('Idle')
        setOpen(false)
        navigate('/locate_tickets');
    }

    const onActive = () => {
        setState('Active')
        setOpen(false)
    }

    const onPrompt = () => {
        setState('Prompted')
        setOpen(true)
    }

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    const handleStillHere = () => {
        activate()
    }

    const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
    const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

    return (
        <>
            <CustomModal formID="logout" show={open} modalBody={
                <Row className="custom-modal-body">
                    <h3>{t("t:IDLE_SESSION.TITLE")}</h3>
                    <p>
                        <span className="d-flex mb-1">
                            {t("t:IDLE_SESSION.DESCRIPTION_1")}
                        </span>
                        <span className="d-flex mb-10">
                            {t("t:IDLE_SESSION.DESCRIPTION_2")}
                        </span>
                    </p>
                    <p>
                        <span className="d-flex mb-10"> {t("t:IDLE_SESSION.REDIRECTING_IN",{ remaining: remaining})}</span>
                    </p>
                    <Button variant="primary" onClick={handleStillHere}>{t("t:IDLE_SESSION.STAY_CONNECTED")}</Button>
                </Row>
            }/>

        </>
    )
}
