import React, { useCallback } from "react";
import externalParamsService from "../../services/external-params.service";
import useDidMountEffect from "../../hooks/useDidMountEffect";

interface AuthState {
  userName: string;
  isAuthenticated: boolean;
}

export type SiteSettings = {
  isRtl: boolean;
  languageId: string;
  authState: AuthState;
  isAuthStart: boolean;
  onChangeLanguage(languageId: string): void;
  onLogin(data: AuthState): any;
  onAuthStart(payload: boolean): any;
};

export const SiteContext = React.createContext<SiteSettings | null>(null);

const SiteProvider: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const [isRtl, setIsRtl] = React.useState<boolean>(true);
  const [languageId, setLanguageId] = React.useState<string>('he');
  const [authState, setAuthState] = React.useState<AuthState>({userName: "", isAuthenticated: false});
  const [isAuthStart, setIsAuthStart] = React.useState<boolean>(false);

  React.useEffect(() => {
    const authData = externalParamsService.getAuth();
    if (authData) {
      setAuthState({userName: authData.userName, isAuthenticated: authData.isAuthenticated});
    }
  }, []);

  useDidMountEffect(() => {
    if (authState.userName && authState.isAuthenticated) {
      externalParamsService.setAuth(authState);
    }
  }, [authState.userName, authState.isAuthenticated]);

  const handleChangeLanguage = useCallback((languageId: string): void => {
    setLanguageId(languageId);
    setIsRtl(languageId === 'he');
  }, []);

  const handleLogin = (data: AuthState) => setAuthState(data);

  const handleAuthStart = (payload: boolean) => setIsAuthStart(payload);

  const contextValue = {
    isRtl,
    languageId,
    authState,
    isAuthStart,
    onLogin: handleLogin,
    onAuthStart: handleAuthStart,
    onChangeLanguage: handleChangeLanguage,
  };

  return <SiteContext.Provider value={contextValue} {...props} />;
};

export default SiteProvider;
