import './CardButton.scss';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

type CardButtonVariant = 'email' | 'pending' | 'cancel';

type Props = {
    type?: 'button' | 'submit' | 'reset' | undefined;
    variant?: CardButtonVariant;
    title: string;
    className?: string;
    disabled?: boolean;
    ariaLabel?: string;
    onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
};

const CardButton: React.FC<Props> = ({
    type = 'button', 
    variant,
    title, 
    className, 
    disabled, 
    ariaLabel, 
    onClick
}: Props) => {

    const { t } = useTranslation();

    const isEmail = variant === 'email';
    const isPending = variant === 'pending';
    const isCancel = variant === 'cancel';

    return (
        <button
            type={type}
            aria-label={t(`t:${ariaLabel}`)}
            className={clsx(`card-btn ${className ? className : ''}`, {
                'email-btn': isEmail,
                'pending-btn': isPending,
                'cancel-btn': isCancel,
                'disabled-btn': disabled,
            })}
            disabled={disabled}
            onClick={onClick}
        >
            {t(`t:${title}`)}
        </button>
    );
};

export { CardButton };