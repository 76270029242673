import "./App.css";
import { useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";
import { SiteContext } from "./store/context/siteContext";
import externalParamsService, { ExternalParams } from "./services/external-params.service";
import { UrlUtils } from "./utils/urlUtils";
import "./translations/i18n";
import i18n from "i18next";
import HomePage from "./pages/Homepage/HomePage";
import LocateTickets from "./pages/LocateTickets/LocateTicket";
import { TicketsList } from "./pages/LocateTickets/components/TicketsList/TicketsList";
import { Accessibility } from "./pages/Accessibility";
import { FAQPage } from "./pages/FAQPage";
import { LegalPage } from "./pages/LegalPage";
import { TermsPage } from "./pages/TermsPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";

function App() {
  const { isRtl, onChangeLanguage, languageId } = useContext(SiteContext);
  document.documentElement.dir = isRtl ? "rtl" : "ltr";
  document.documentElement.lang = languageId;
  document.body.style = isRtl ? "direction: rtl" : "";

  useEffect(() => {
    const storedLanguageCode = externalParamsService.getLanguageId();
    const externalLanguageCode = UrlUtils.getQueryParam(ExternalParams.LanguageId);
    const languageCodeToUse = externalLanguageCode ? externalLanguageCode : storedLanguageCode ? storedLanguageCode : languageId;
    if (externalLanguageCode) externalParamsService.setLanguageId(languageCodeToUse);
    onChangeLanguage(languageCodeToUse);
    i18n.changeLanguage(languageCodeToUse);
  }, [onChangeLanguage, languageId]);

  const routes = (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/locate_tickets" element={<LocateTickets />} />
      <Route path="/tickets_list" element={<TicketsList />} />
      <Route path="/accessibility" element={<Accessibility />} />
      <Route path="/questions" element={<FAQPage />} />
      <Route path="/legal" element={<LegalPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/500" element={<ErrorPage error_code={500} />} />
      <Route path="*" element={<ErrorPage error_code={404} />} />
    </Routes>
  );

  const maintenanceRoute = (
    <Routes>
      <Route path="*" element={<ErrorPage error_code={503} />} />
    </Routes>
  );

  return (
    <ThemeProvider dir={isRtl ? 'rtl' : 'ltr'}>
      <div className="App">
        <div className="site-content">
          <BrowserRouter>
            {process.env.REACT_APP_SITE_MODE === "MAINTENANCE"
              ? maintenanceRoute
              : routes}
          </BrowserRouter>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
