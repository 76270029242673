import {axios} from "../../services/https.service";
import {storageService} from "../../services/storage.service";
import {useNavigate} from "react-router-dom";
import {FC, useEffect} from "react";

const CheckLogin = () => {
    const navigate = useNavigate();
    const isLoggedIn = storageService.getFromLocalStorage('loggedinUser');
    const loginSuccess = JSON.parse(isLoggedIn)?.status_code === 0;
    if(!loginSuccess){
        navigate('/locate_tickets');
    } else {
        navigate('/tickets_list');
    }
}

const SessionExpired = ({ children }) => {

    const navigate = useNavigate();

    useEffect(() => {

        const resInterceptor = async (response: any) =>
            {
                const { data: { status_code } } = response;
                if (!status_code) {
                    return response;
                }
                const forbiddenStatusCodes = [20002, 20006, 20000];
                const expirationsStatusCodes = [20001, 20003, 20004, 10000];

                const isLoggedIn = storageService.getFromLocalStorage('loggedinUser');
                const loginSuccess = JSON.parse(isLoggedIn)?.status_code === 0;
                if(!loginSuccess){
                    navigate('/locate_tickets');
                }

                if (forbiddenStatusCodes.includes(status_code) && loginSuccess) {
                    try {
                        storageService.removeFromLocalStorage('loggedinUser');
                        storageService.removeFromLocalStorage('refresh_token');
                    } catch (error) {
                        console.log(error);
                    } finally {
                        // console.log("error");
                        // dispatch(setError(formatMessage({ id: 'ERROR.ERROR_OCCURED' })));
                        navigate('/locate_tickets');
                    }
                } else if (expirationsStatusCodes.includes(status_code)) {
                    if (status_code === 20001) {
                        navigate('/');
                        return;
                    }
                    if ((status_code === 10000) && loginSuccess) {
                        // Mostly happen when making a new build and the session doesn't exist in the backend
                        // but the loggedinUser in localStorage still does.
                        try {
                            storageService.removeFromLocalStorage('loggedinUser');
                            storageService.removeFromLocalStorage('refresh_token');
                        } catch (error) {
                            console.log(error);
                        } finally {
                            console.log("error");
                            // dispatch(setError(formatMessage({ id: 'ERROR.ERROR_OCCURED' })));
                        }
                        navigate('/locate_tickets');
                        return;
                    }
                    //     await logout();
                    //     //user not found in the backend meaning there is no session
                    //     //happens when the user come back after he closed the
                    //     //browser and came back after a while
                    //     if (status_code === 20000) throw error;
                    //     dispatch(setError(formatMessage({ id: 'ERROR.ERROR_OCCURED' })));
                }
                return response;
            }


        const errInterceptor = error => {

            if (error.response.status === 401) {
                navigate('/login');
            }

            return Promise.reject(error);
        }


        const interceptor = axios.interceptors.response.use(resInterceptor, errInterceptor);

        return () => axios.interceptors.response.eject(interceptor);

    }, [navigate])

    return children;
}

export { SessionExpired }