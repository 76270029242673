import './CustomModal.scss';
import React from 'react';
import {Button, Modal} from "react-bootstrap";
interface Props {
    formID: string | undefined
    buttonText?: any
    modalBody: any
    modalFooter?: any
    isButton?: boolean
    show?: boolean
    size?: 'sm' | 'lg' | 'xl'
    dialogClassName?: string;
    handleShow?: () => void
    handleClose?: () => void
}

const CustomModal = ({ formID, buttonText, size, modalBody, modalFooter, isButton, show, handleShow, handleClose, dialogClassName = 'modal-35vw' }: Props) => {
    const dir = "rtl";
    return (
        <>
            {isButton && <Button variant="primary" className="btn btn-sm btn-primary" onClick={handleShow}>
                {buttonText}
            </Button>}

            {!isButton && <span onClick={handleShow}>
                {buttonText}
            </span>}

            <Modal
                // style={{ direction: dir }}
                size={size}
                show={show} onHide={handleClose}
                aria-labelledby="modal-title"
                centered
                dialogClassName={dialogClassName}
            >
                <Modal.Header className="border-none" closeButton>
                </Modal.Header>
                <Modal.Body>{modalBody}</Modal.Body>
                {modalFooter &&
                    <Modal.Footer>
                        {modalFooter}
                    </Modal.Footer>
                }
            </Modal>
        </>
    );
}

export default CustomModal;