import './LocateTicket.scss';
import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import {Header} from "../../components/Header/Header";
import {Footer} from "../../components/Footer/Footer";
import {Card, Col, Image, Row} from "react-bootstrap";
import OtpForm from "./components/OtpForm/OtpForm";
import {OtpConfirmForm} from "./components/OtpConfirmForm/OtpConfirmForm";
import {PageLink} from "../../models/bredcrumbs.model";
import {otpInitialValues, OtpResponse, PhoneNumber} from "./core/_models";
import {NoTicketsFound} from "./components/NoTicketsFound/NoTicketsFound";
import ErrorPage from "../ErrorPage/ErrorPage";
import {CustomerServiceButton} from "../../components/CustomerServiceBtn/CustomerServiceButton";
import {userSessionIsBlocked} from "../../services/utils.service";

const LocateTicket = () => {
    const [sendOtpResponse, setSendOtpResponse] = useState<OtpResponse>(otpInitialValues);
    const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>({
        country_area_code: '',
        area_code: '',
        phone_number: ''
    });
    const [apiError, setApiError] = useState<boolean>(false);
    const { t } = useTranslation();
    
    const breadcrumbs: Array<PageLink> = [
        {
            title: t("t:HEADER.HOMEPAGE"),
            path: '/',
            isSeparator: true,
            isActive: false,
        },
        {
            title: t("t:LOCATE_TICKETS.TITLE"),
            path: '/locate_tickets',
            isSeparator: false,
            isActive: true,
        }
    ];

    const handleOtp = (response: OtpResponse) => {
        setSendOtpResponse(response);
    };

    const handlePhoneNumber = (props: PhoneNumber) => {
        setPhoneNumber(props);
    };

    const handleApiError = (value: boolean) => {
        setApiError(value)
    };


    const isBlocked = userSessionIsBlocked();

    const userBlockedMsg = <>
        <div className="no-tickets">
            <Row>
                <Card.Title>{t("t:LOCATE_TICKETS.BLOCKED_NUMBER")}</Card.Title>
                <CustomerServiceButton/>
            </Row>
        </div>
    </>

    return (
        <>
            <Header breadcrumbs={breadcrumbs}/>
            <div className="container content-container">
                {apiError ? <ErrorPage error_code={500}/> :
                    <>
                        <Row className="content-title-container">
                            <h1 className="content-title">{t("t:LOCATE_TICKETS.TITLE")}</h1>
                        </Row>
                        <Row className="locate-tickets-container">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="locate-ticket-card border-0 px-lg-4">
                                    <Card.Body className="px-lg-5 py-5">
                                        {!isBlocked && !sendOtpResponse.showResult &&
                                            <OtpForm handleOtp={handleOtp} handlePhoneNumber={handlePhoneNumber}
                                                     handleApiError={handleApiError}/>}
                                        {(sendOtpResponse.showResult && sendOtpResponse.success) &&
                                            <OtpConfirmForm handleOtp={handleOtp} phoneNumber={phoneNumber}
                                                            handleApiError={handleApiError}/>}
                                        {((sendOtpResponse.showResult && sendOtpResponse.block_user === "Y") || isBlocked ) && userBlockedMsg}
                                        {(sendOtpResponse.showResult && sendOtpResponse.status_code === 10010) &&
                                            <NoTicketsFound/>}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={8} lg={6} xl={6}>
                                <Image src='media/images/THG_M83_04.png' className="side-image w-100 h-100" alt="locate-tickets"/>
                            </Col>
                        </Row>
                    </>}
            </div>
            <Footer/>
        </>
    );
}

export default LocateTicket;