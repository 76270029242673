import { useEffect } from 'react';

export type EventListenerPair = [string, EventListenerOrEventListenerObject];

function useEventListeners(eventListeners: EventListenerPair[]) {
  useEffect(() => {
    for (const [eventName, eventHandler] of eventListeners) {
      window.addEventListener(eventName, eventHandler);
    }

    return () => {
      for (const [eventName, eventHandler] of eventListeners) {
        window.removeEventListener(eventName, eventHandler);
      }
    };
  }, [eventListeners]);
}

export { useEventListeners };