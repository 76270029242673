import "./Footer.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { CustomerServiceButton } from "../CustomerServiceBtn/CustomerServiceButton";

export const Footer = () => {
  const { t } = useTranslation();

  const termsLinks = (
    <Col className="conditions-container" lg={5} xl={4}>
      <div>
        <Link to="/terms" className="condition-link">
          {t("t:FOOTER.TERMS_LINK")}
        </Link>
        <span className="seprator">{"|"}</span>
        <Link to="/legal" className="condition-link">
          {t("t:FOOTER.LEGAL_LINK")}
        </Link>
        <span className="seprator">{"|"}</span>
        <Link
          aria-label={t("t:FOOTER.ACCESSIBILITY_STATEMENT_ARIA_LABEL")}
          to="/accessibility"
          className="condition-link"
        >
          {t("t:FOOTER.ACCESSIBILITY_STATEMENT_LINK")}
        </Link>
      </div>
      <span className="address">{t("t:FOOTER.ADDRESS")}</span>
    </Col>
  );

  const termsAndConditions = (
    <Row className="mx-0 flex-md-column-reverse justify-content-lg-center terms-and-conditions-container">
      <Col className="m-0 p-0 d-flex justify-content-center order-1" lg={12}>
        <span className="terms">{t("t:FOOTER.TERMS")}</span>
      </Col>
      <Col className="w-100 d-flex justify-content-center" lg={12}>
        <span className="info-text">{t("t:FOOTER.INFO")}</span>
      </Col>
      <div className="d-sm-block d-md-none d-block text-center">
        {termsLinks}
      </div>
    </Row>
  );

  return (
    <>
      <footer id="site-footer">
        <div className="container">
          <Row className="mx-0 content-wrapper">
            <Col
              sm={12}
              md={3}
              lg={3}
              xxl={3}
              className="customer-service-btn text-start text-sm-center mb-sm-3"
            >
              <CustomerServiceButton />
              <div className="d-sm-block d-md-none d-block text-center">
                {termsAndConditions}
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              xxl={6}
              className="footer-center text-center"
            >
              <span className="customer-service-text">
                {t("t:FOOTER.CUSTOMER_SERVICE_TEXT")}
              </span>
              <div className="d-sm-none d-md-block d-none">
                {termsAndConditions}
              </div>
            </Col>
            <Col
              sm={12}
              md={3}
              lg={3}
              xxl={3}
              className="text-center d-md-flex flex-md-column justify-content-md-center align-items-md-center term-links-container"
            >
              <img
                className="logo"
                src="media/images/finitifun-logo.svg"
                alt="site-logo"
              />
              <div className="d-sm-none d-md-block d-none">{termsLinks}</div>
            </Col>
          </Row>
        </div>
      </footer>
    </>
  );
};
