import React from "react";
import "./OvalLoader.scss";

interface props {
    className?: string,
    isLoading? : boolean
}

const OvalLoader = ({className,isLoading}:props) => {
    const html = document.getElementsByTagName('html')[0]

    if(isLoading){
        html.classList.add('lock-scroll')
    } else {
        html.classList.remove('lock-scroll')
    }

    return (
      <>
        {isLoading && (
          <div className={`loaderWrapper ${className}`}>
            <div className="content-container">
              <img
                className="loader"
                src={require("../../assets/img/new_cal_loader.gif")}
                alt="loading..."
              />
            </div>
          </div>
        )}
      </>
    );
};

export default OvalLoader