import "./Accessibility.scss";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { PageLink } from "../../models/bredcrumbs.model";
import { clientService } from '../../services/client.service';
import parse from "html-react-parser";
import OvalLoader from "../../components/Loader/OvalLoader";
import ErrorPage from "../ErrorPage/ErrorPage";


export const Accessibility: React.FC = () => {
  const [data, setData] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<boolean>(false);
  const { t } = useTranslation();

  const breadcrumbs: Array<PageLink> = useMemo(() => [
    {
      title: t("t:HEADER.HOMEPAGE"),
      path: "/",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("t:FOOTER.ACCESSIBILITY_STATEMENT_LINK"),
      path: "/accessibility",
      isSeparator: false,
      isActive: true,
    },
  ], [t]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const response = await clientService.getAccessibility();
        setData(response.accessibility);
      } catch (error: unknown) {
        console.log({ error });
        setApiError(true);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <OvalLoader className="overlayWrapper" isLoading={isLoading} />
      <Header breadcrumbs={breadcrumbs} />
      <div className="container content-container">
        {apiError && <ErrorPage error_code={500} />}
        {!isLoading && !apiError && data.length > 0 && (
          <div className="accessibility">
            {parse(data)}
            <div className="extra-margin" />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};
