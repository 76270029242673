import "./Breadcrumbs.scss";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { PageLink } from "../../../models/bredcrumbs.model";

type Props = {
  breadcrumbs: Array<PageLink>;
};

const Breadcrumbs: React.FC<Props> = ({ breadcrumbs }) => {
  const { t } = useTranslation();

  return (
    <>
      {Array.isArray(breadcrumbs) && breadcrumbs.length > 0 && (
        <div className="breadcrumb-container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {breadcrumbs?.map((breadcrumb: PageLink) => {
                const ariaCurrent = breadcrumb.isActive ? "page" : undefined;
                return (
                  <li
                    key={breadcrumb.title}
                    className={clsx(`breadcrumb-item`, {
                      active: breadcrumb.isActive,
                    })}
                    aria-current={ariaCurrent}
                  >
                    {breadcrumb.isActive ? (
                      <span>{breadcrumb.title}</span>
                    ) : (
                      <a
                        aria-label={t("t:HEADER.NAV_BAR_ARIA_LABEL", {
                          breadcrumb: breadcrumb.title,
                        })}
                        href={breadcrumb.path}
                      >
                        {breadcrumb.title}
                      </a>
                    )}
                  </li>
                );
              })}
            </ol>
          </nav>
        </div>
      )}
    </>
  );
};

export { Breadcrumbs };
