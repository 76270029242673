import "./Header.scss";
import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PageLink } from "../../models/bredcrumbs.model";
import { SiteContext } from "../../store/context/siteContext";
import { LoginStatus } from "../../pages/Homepage/HomePage";
import { DiscountPopup } from "./components/DiscountPopup";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { HeaderMenu } from "./components/HeaderMenu";
import externalParamsService from "../../services/external-params.service";

interface HeaderProps {
  breadcrumbs?: Array<PageLink>;
  loginStatus?: LoginStatus;
}

export const Header: React.FC<HeaderProps> = ({
  breadcrumbs,
  loginStatus,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { authState, onLogin, onAuthStart } = useContext(SiteContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (!authState.isAuthenticated) setOpenToast(true);
    else setOpenToast(false);
  }, [authState.isAuthenticated]);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleCloseToast = () => setOpenToast(false);

  const authClickHandler = () => {
    if (!authState.isAuthenticated) {
      if (location.pathname !== "/") {
        navigate("/");
      }

      onAuthStart(true);
    } else {
      onLogin({ userName: "", isAuthenticated: false });
      externalParamsService.removeExternalToken();
      externalParamsService.removeAuth();
    }

    handleCloseToast();
    setShow(false);
  };

  return (
    <>
      <div className="sticky-top bg-white">
        <div className="container position-relative app-header-wrapper">
          <DiscountPopup
            show={openToast}
            onClick={authClickHandler}
            onClose={handleCloseToast}
          />
          <div className="site-header">
            <div className="action-btn-container">
              <button className="action-btn" onClick={handleShow}>
                <img
                  className="hamburger-icon"
                  src="media/images/menu-open-icon-web.svg"
                  alt={t("t:HEADER.OPEN_MENU_ARIA_LABEL")}
                />
              </button>
              <div className="header-username-container">
                {authState.isAuthenticated && authState.userName.length > 0 ? (
                  <span className="header-username">{`${t(
                    "t:HEADER.MENU_USER_NAME_GREETING"
                  )} ${authState.userName}`}</span>
                ) : (
                  <button
                    className="header-username-button"
                    onClick={authClickHandler}
                  >
                    {t("t:HEADER.USER_NAME")}
                  </button>
                )}
              </div>
            </div>
            <Link
              reloadDocument
              to="/"
              aria-label={t("t:HEADER.LOGO_LINK_ARIA_LABEL")}
              className="site-logo-link"
            >
              <img
                className="site-logo"
                src="media/images/cal-travel-logo.svg"
                alt="cal travel logo"
              />
            </Link>
          </div>
          <HeaderMenu 
             show={show}
             onClose={handleClose}
             onAuthClick={authClickHandler}
          />
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      </div>
    </>
  );
};
