import "./FAQPage.scss";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Row, Col } from "react-bootstrap";
import { clientService } from "../../services/client.service";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { PageLink } from "../../models/bredcrumbs.model";
import OvalLoader from "../../components/Loader/OvalLoader";
import ErrorPage from "../ErrorPage/ErrorPage";
import { FAQ } from "../../common/types";

const pattern = /\b((https?|ftp|file):\/\/|(www|ftp)\.)[-A-Z0-9+&@#/%?=~_|$!:,.;]*[A-Z0-9+&@#/%=~_|$]/ig;
const clearPattern = /(?:https?|ftp):\/\/[\n\S]+/g;

export const FAQPage: React.FC = () => {
    const [faqs, setFaqs] = useState<FAQ[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [apiError, setApiError] = useState<boolean>(false);
    const { t } = useTranslation();

    const breadcrumbs: Array<PageLink> = useMemo(
      () => [
        {
          title: t("t:HEADER.HOMEPAGE"),
          path: "/",
          isSeparator: true,
          isActive: false,
        },
        {
          title: t("t:HEADER.FAQ"),
          path: "/questions",
          isSeparator: false,
          isActive: true,
        },
      ],
      [t]
    );

    let middleIndex: any;
    let firstHalf: any;
    let secondHalf: any;

    if (faqs.length > 0) {
        middleIndex = Math.ceil(faqs.length / 2);
        firstHalf = faqs.slice(0, middleIndex);
        secondHalf = faqs.slice(middleIndex);
    }

    useEffect(() => {
      async function fetchFAQs(): Promise<void> {
        try {
          setIsLoading(true);
          const response = await clientService.getFAQs();
          setFaqs(response);
        } catch (error) {
          console.log({ error });
          setApiError(true);
        } finally {
          setIsLoading(false);
        }
      }

      fetchFAQs();
    }, []);

    const replaceURLWithLinks = (links: string[]): JSX.Element => {
      return (
        <span>
          {links !== null &&
            links.length > 0 &&
            links.map((link: string, index: number) => {
            const label = new URL(link).hostname;
              return (
                <a
                  key={`${link}-${index}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={link}
                >
                  {label}
                </a>
              );
            })}
        </span>
      );
    };

    return (
        <>
            <OvalLoader className="overlayWrapper" isLoading={isLoading} />
            <Header breadcrumbs={breadcrumbs} />
            <div className="container content-container faq-main">
                {apiError && <ErrorPage error_code={500} />}
                {!isLoading && !apiError && (
                    <>
                        <div className="header-container">
                            <h1 className="main-header">{t("t:FAQ.HEADER")}</h1>
                        </div>
                        <Row className="accordions-container">
                            <Col sm={12} md={6} className="accordions-side-right">
                                {Array.isArray(firstHalf) && firstHalf.length > 0 && firstHalf.map((faq: FAQ, index: number) => {
                                    const match = faq.answer.match(pattern);
                                    return (
                                        <Accordion key={faq.faq_id} bsPrefix="accordion">
                                            <Accordion.Item bsPrefix="accordion-item" eventKey={index.toString()}>
                                                <Accordion.Header bsPrefix="accordion-header">{faq.question}</Accordion.Header>
                                                {match !== null
                                                    ? (
                                                        <Accordion.Body bsPrefix="accordion-body">
                                                            {faq.answer.replaceAll(clearPattern, "")}
                                                            {replaceURLWithLinks(match)}
                                                        </Accordion.Body>
                                                    ) : <Accordion.Body bsPrefix="accordion-body">{faq.answer}</Accordion.Body>
                                                }
                                            </Accordion.Item>
                                        </Accordion>
                                    )
                                })}
                            </Col>
                            <Col sm={12} md={6} className="accordions-side-left">
                                {Array.isArray(secondHalf) && secondHalf.length > 0 && secondHalf.map((faq: FAQ, index: number) => {
                                    const match = faq.answer.match(pattern);
                                    return (
                                        <Accordion key={faq.faq_id} bsPrefix="accordion">
                                            <Accordion.Item bsPrefix="accordion-item" eventKey={index.toString()}>
                                                <Accordion.Header bsPrefix="accordion-header">{faq.question}</Accordion.Header>
                                                {match !== null
                                                    ? (
                                                        <Accordion.Body bsPrefix="accordion-body">
                                                            {faq.answer.replaceAll(clearPattern, "")}
                                                            {replaceURLWithLinks(match)}
                                                        </Accordion.Body>
                                                    ) : <Accordion.Body bsPrefix="accordion-body">{faq.answer}</Accordion.Body>
                                                }
                                            </Accordion.Item>
                                        </Accordion>
                                    )
                                })}
                            </Col>
                        </Row>
                    </>
                )}
            </div>
            <Footer />
        </>
    );
};