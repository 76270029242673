import React from "react";
import {Header} from "../../components/Header/Header";
import {Footer} from "../../components/Footer/Footer";
import {Col, Image, Row} from "react-bootstrap";
import {t} from "i18next";

interface props {
    error_code: number
}

const ErrorPage = ({error_code}: props) => {
    return (
        <>
            {error_code !== 500 && <Header />}
            <div className="content-container">
                <Row>
                    <Row className="justify-content-center">
                        <Col className="col-6">
                            <Image src={`media/images/error${error_code}.svg`} alt={`Error ${error_code}`}/>
                        </Col>
                    </Row>
                    {error_code !== 404 &&
                        <Row className="justify-content-center">
                            <Col className="col-6">
                                {t(`t:ERROR.${error_code}`)}
                            </Col>
                        </Row>
                    }
                </Row>
            </div>
            {error_code !== 500 && <Footer/>}
        </>
    );
}
export default ErrorPage;
