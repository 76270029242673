import { httpService } from './https.service';

export const homepageService = {
    getSiteIframeToken,
}

async function getSiteIframeToken() {
    try {
        const response = await httpService.post('login/token', {});

        if (response.status_code !== 0) {
            throw response;
        }
        return response?.token;
    } catch (error) {
        throw error;
    }
}