import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SiteContext } from "../../../store/context/siteContext";
import "./DiscountPopup.scss";

type Props = {
  show: boolean;
  onClose(): void;
  onClick(): void;
};

export const DiscountPopup: React.FC<Props> = ({ show, onClose, onClick }) => {
  const { isRtl } = useContext(SiteContext);
  const { t } = useTranslation();

  return (
    <>
      {show && (
        <div className={isRtl ? 'popup-container-rtl' : 'popup-container-ltr'}>
          <div className="popup-content-container">
            <button className="popup-content" onClick={onClick} tabIndex={0}>
              {t("t:HEADER.DISCOUNT_POPUP")}
            </button>
            <button
              className="popup-close-button"
              onClick={onClose}
              tabIndex={0}
            >
              <img
                className="popup-close-button-icon"
                src="media/images/close-icon.svg"
                alt="close-icon"
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
};
