import { httpService } from './https.service';
import { storageService } from './storage.service';

export async function getCSRF() {
    try {
        const refresh_token = storageService.getRefreshToken();
        const response = await httpService.post('csrf_token', { refresh_token });
        if (response.status_code !== 0) {
            throw response;
        }
        return response?.csrf_token;
    } catch (error: any) {
        if (error?.status_code !== 0) {
            throw error;
        }
        throw error;
    }
}

export async function createTokens() {
    try {
        const refresh_token = storageService.getRefreshToken();
        const response = await httpService.post('create_tokens', {
            refresh_token
            // language_id:'he'
        });
        if (response?.status_code !== 0) {
            throw response;
        }
        storageService.setRefreshToken(response?.refresh_token);
    } catch (error) {
        throw error;
    }
}