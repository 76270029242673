import {Button, Card, Row} from "react-bootstrap";
import {t} from "i18next";
import React from "react";
import {useNavigate} from "react-router-dom";

interface props {
    redirectUrl?: string
}

export const NoTicketsFound = ({redirectUrl}:props) => {
    const navigate = useNavigate();
    return (
        <div className="no-tickets">
            <Row>
                <Card.Title>{t("t:LOCATE_TICKETS.NO_TICKETS_FOUND")}</Card.Title>
                <Button onClick={() => { redirectUrl ? navigate(redirectUrl) : window.location.reload()}}>
                    {t("t:LOCATE_TICKETS.NO_TICKETS_RETRY")}
                </Button>
            </Row>
        </div>
    );
}

