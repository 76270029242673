import { httpService } from './https.service';
import { PhoneNumber, Order } from "../pages/LocateTickets/core/_models";
import { orderDateIsPast } from './utils.service';

export const orderService = {
    getTicketsList,
    cancelOrder,
    sendEmail
}

async function getTicketsList(phoneNumber: PhoneNumber) {
    try {
        const response = await httpService.post('orders/get',  phoneNumber); 
        if (response.status_code !== 0) {
            throw response;
        }

        const sortedByDate = response.orders.sort((a: Order, b: Order) => {
          return (
            Number(new Date(b.create_date)) - Number(new Date(a.create_date))
          );
        });

        const sortedByCategory = sortedByDate.reduce(
            (acc: {
                confirmed: Array<Order>;
                pending: Array<Order>;
                pastDate: Array<Order>;
            }, 
            currOrder: Order
            ) => {
            const datePassed = orderDateIsPast(
                currOrder.execution_start_date, 
                currOrder.execution_start_time,
                currOrder.timezone,
            );
            const orderIsConfirmed = currOrder.order_status_id === 10;
            const orderIsPending = currOrder.order_status_id === 8 || currOrder.order_status_id === 9;
            if (!datePassed) {
                if (orderIsConfirmed) acc.confirmed.push(currOrder);  
                if (orderIsPending) acc.pending.push(currOrder);
            } else {
                acc.pastDate.push(currOrder);
            }
    
            return acc;
        }, { confirmed: [], pending: [], pastDate: [] })

        return { orders: sortedByCategory, total: response.orders.length ?? 0 };

    } catch (error) {
        console.log({ error });
        throw error;
    }
}

async function cancelOrder(order_id: number) {
    try {
        const response = await httpService.post('order/cancel', {order_id});
        if (response.status_code !== 0) {
            throw response;
        }

        return response;
    } catch (error) {
        console.log({ error });
        throw error;
    }
}

async function sendEmail(order_id: number) {
    try {
        const response = await httpService.post('order/voucher/send', {order_id});
        if (response.status_code !== 0) {
            throw response;
        }

        return response;
    } catch (error) {
        console.log({ error });
        throw error;
    }
}