import React, { useState } from "react";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { IFrame } from "../../components/IFrame/IFrame";
import "./HomePage.scss";

export type LoginStatus = {
  isSuccsess: boolean;
  status_code: number;
  status_message: string;
  name: string;
};

const HomePage = () => {
  const [loginStatus, setLoginStatus] = useState<LoginStatus>();
  
  const handleLoginStatus = (statusData: LoginStatus) => {
    setLoginStatus(statusData);
  };

  return (
    <>
      <Header 
        breadcrumbs={[]} 
        loginStatus={loginStatus} 
      />
      <div className="homepage-content-container">
        <IFrame onLoginStatus={handleLoginStatus} />
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
