import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { clientService } from "../../services/client.service";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { PageLink } from "../../models/bredcrumbs.model";
import OvalLoader from "../../components/Loader/OvalLoader";
import ErrorPage from "../ErrorPage/ErrorPage";
import "./TermsPage.scss";

export const TermsPage: React.FC = () => {
  const [termsHtml, setTermsHtml] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<boolean>(false);
  const { t } = useTranslation();

  const breadcrumbs: Array<PageLink> = useMemo(() => [
    {
      title: t("t:HEADER.HOMEPAGE"),
      path: "/",
      isSeparator: true,
      isActive: false,
    },
    {
      title: t("t:HEADER.TERMS_AND_CONDITIONS"),
      path: "/terms",
      isSeparator: false,
      isActive: true,
    },
  ], [t]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function fetchTermsHtml() {
      try {
        setIsLoading(true);
        const response = await clientService.getTermsData();
        setTermsHtml(response);
      } catch (error) {
        console.log({ error });
        setApiError(true);
      } finally {
        setIsLoading(false);
      }
    }

    fetchTermsHtml();
  }, []);

  return (
    <>
      <OvalLoader className="overlayWrapper" isLoading={isLoading} />
      <Header breadcrumbs={breadcrumbs} />
      <div className="container content-container">
        {apiError && <ErrorPage error_code={500} />}
        {!isLoading && !apiError && termsHtml.length > 0 && (
          <>
            {parse(termsHtml)}
            <div className="extra-margin" />
          </>
        )}
      </div>
      <Footer />
    </>
  );
};
