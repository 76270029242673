import "./HeaderMenu.scss";
import clsx from "clsx";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useWindowDimensions } from "../../../hooks/useWindowDemensions";
import { SiteContext } from "../../../store/context/siteContext";
import { Offcanvas } from "react-bootstrap";

interface MenuItem {
  id: number;
  src: string;
  alt: string;
  path: string;
  title: string;
  arialabel: string;
}

const menuItems: MenuItem[] = [
  {
    id: 1,
    src: "media/images/menu-orders-icon.svg",
    alt: "orders icon",
    path: "/locate_tickets",
    title: "HEADER.MENU_ITEM_LOCATE_TICKETS",
    arialabel: "HEADER.LOCATE_TICKETS_ARIA_LABEL",
  },
  {
    id: 2,
    src: "media/images/menu-questions-icon.svg",
    alt: "questions and answers icon",
    path: "/questions",
    title: "HEADER.MENU_ITEM_QUESTION_ANSWER",
    arialabel: "HEADER.QUESTIONS_AND_ANSWERS_ARIA_LABEL",
  },
  {
    id: 3,
    src: "media/images/menu-accessibility-icon.svg",
    alt: "accessibility icon",
    path: "/accessibility",
    title: "HEADER.MENU_ITEM_ACCESSIBILITY_STATEMENT",
    arialabel: "HEADER.ACCESSIBILITY_ARIA_LABEL",
  },
];

type Props = {
  show: boolean;
  onClose: () => void;
  onAuthClick: () => void;
};

const HeaderMenu: React.FC<Props> = ({ show, onClose, onAuthClick }) => {
  const { authState, isRtl } = useContext(SiteContext);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <Offcanvas
      show={show}
      scroll={true}
      onHide={onClose}
      placement='start'
      bsPrefix={`offcanvas ${
        isRtl ? "custom-offcanvas" : "custom-offcanvas-ltr"
      }`}
      backdropClassName="custom-backdrop"
    >
      <Offcanvas.Header bsPrefix="offcanvas-header custom-header">
        {width <= 768 && (
          <Offcanvas.Title className="title">
            <img
              className="site-logo"
              src="media/images/cal-travel-logo.svg"
              alt="cal travel logo"
            />
          </Offcanvas.Title>
        )}
        <button className="action-btn" onClick={onClose}>
          <img src="media/images/menu-close-icon.svg" alt="close menu icon" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body bsPrefix="offcanvas-body custom-body">
        <nav className="flex-grow-1">
          <ul className="list-group-container">
            <li className="list-group-item list-item">
              <img src="media/images/menu-user-icon.svg" alt="user icon" />
              <p className="list-item-username">
                {authState.isAuthenticated && authState.userName.length > 0
                  ? `${t("t:HEADER.MENU_USER_NAME_GREETING")} ${
                      authState.userName
                    }`
                  : `${t("t:HEADER.MENU_DEFAULT_USER_NAME")}`}
              </p>
            </li>
            {/* List of navigation links */}
            {menuItems.map((item: MenuItem) => (
              <li key={item.id} className="list-group-item list-item">
                <img src={item.src} alt={item.alt} />
                <Link
                  to={item.path}
                  aria-label={t(`t:${item.arialabel}`)}
                  className="list-item-link"
                >
                  {t(`t:${item.title}`)}
                </Link>
              </li>
            ))}
            <li className="list-group-item list-item">
              <img
                src="media/images/menu-login-logout-icon.svg"
                alt="login logout icon"
                className={clsx({'login-icon-reverse': !isRtl})}
              />
              <button
                className="list-item-button"
                aria-label={t("t:HEADER.EXIT_AND_ENTRY_ARIA_LABEL")}
                onClick={onAuthClick}
              >
                {authState.isAuthenticated
                  ? t("t:HEADER.MENU_ITEM_EXIT")
                  : t("t:HEADER.MENU_ITEM_ENTRY")}
              </button>
            </li>
          </ul>
        </nav>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export { HeaderMenu };
